import React from 'react'
import Layout from '../components/layout'
import Header from '../components/header'
import { Link } from 'gatsby'
import SEO from '../components/SEO'
import Card from '../components/Card'

const services = [
  {
    title: 'Commerical and Residential Wiring',
    description: 'From complete property rewiring to installing sockets, lights, and all other electrical points, we ensure your electrical infrastructure is robust and reliable.',
    imageURL: '/plug.png',
    link: 'commercial'
  },
  {
    title: 'Upgrades and Installations',
    description: 'We handle everything from consumer unit upgrades to the installation of appliances, electrical showers, central heating controls, and camera doorbells.',
    imageURL: '/shower.webp',
    link: 'commercial'
  },
  {
    title: 'Inspections and Maintenance',
    description: 'Trust us for your fault finding, electrical inspections, maintenance, and PAT testing needs.',
    imageURL: '/fusebox.jpg',
    link: 'commercial'
  },
  {
    title: 'Lighting Solutions',
    description: "Whether you need extra lights or spotlights, light fitting changes, or garden lighting, we've got you covered.",
    imageURL: '/lightingsmall.webp',
    link: 'commercial'
  },
  {
    title: 'Safety Measures',
    description: 'Count on us for smoke detector installation, landlord certifications, and more to ensure the safety of your property.',
    imageURL: '/smoke.jpg',
    link: 'commercial'
  }
]

const IndexPage = () => {
  return (
    <Layout>
      <SEO />

      <Header siteTitle={'Norfolk Current'} />
<section className="relative sm:h-[80vh] min-h-[750px]">
  <div className="absolute w-full h-full">
  <picture>
      <source media="(max-width: 768px)" srcSet='/mobilehero.webp' />
      <source media="(min-width: 769px)" srcSet='/hero.png' />
      <img
        src='/hero.png'
        alt="Background"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: -1
        }}
      />
    </picture>
    <div
      className="absolute w-full h-full"
      style={{
        backgroundImage: `
          linear-gradient(to left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 100%),
          linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center, right top',
        zIndex: 0
      }}
    ></div>
  </div>
  <div className="container mx-auto px-6 sm:px-2 py-20 relative">
    <div className="w-full md:w-1/2 lg:w-1/3 sm:mt-32">
      <h2 className="text-2xl font-semibold mb-8 text-slate-950">
        Providing reliable commercial and residential electrical services in Norfolk
      </h2>
      <p className="text-2xl font-semibold mb-2 text-blue-800 hover:underline">
        <a href="tel:07469469586">07469 469586</a>
      </p>
      <p className="text-2xl font-semibold mb-8 text-blue-800 hover:underline">
        <a href="mailto:ciarant@norfolkcurrent.co.uk">ciarant@norfolkcurrent.co.uk</a>
      </p>
      <Link to="/contact" className="bg-blue-950 text-white font-bold rounded-lg py-3 px-8 shadow-lg">
        <button className='tracking-wide'>Get a free quote</button>
      </Link>
    </div>
  </div>
</section>

      {/* ============= SERVICES =============== */}

      <section className="container mx-auto px-6 py-12 sm:py-20">
        <h2 className="text-center text-3xl font-semibold mb-10 sm:mb-16 text-slate-950">Our Professional Electrical Services</h2>

        <div className="lg:grid lg:grid-cols-12 gap-4 justify-items-center mb-8 2xl:mx-48 ">
          {/* <div className="col-span-1 lg:col-span-1"></div> */}
          <Card row={1} number={1} title={services[0].title} description={services[0].description} imageURL={services[0].imageURL} id="commercial" />
          <Card row={1} title={services[1].title} description={services[1].description} imageURL={services[1].imageURL} id="upgrades"/>
          {/* <div className="col-span-1 lg:col-span-1"></div> */}
        </div>

        <div className="lg:grid lg:grid-cols-12 gap-4 justify-items-center 2xl:mx-48">
          <Card row={2} title={services[2].title} description={services[2].description} imageURL={services[2].imageURL} id="inspections"/>
          <Card row={2} title={services[3].title} description={services[3].description} imageURL={services[3].imageURL} id="lighting"/>
          <Card row={2} title={services[4].title} description={services[4].description} imageURL={services[4].imageURL} id="safety"/>
        </div>
      </section>

      {/* ============= About us =============== */}

      <section className="pt-2 sm:pt-12 pb-32" style={{ background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(184, 152, 52, 0.4))' }}>
        <div className="container mx-auto">
        <h2 className="text-center text-3xl font-semibold mb-10 sm:mb-16 text-slate-950">Why Choose Us?</h2>
        <div className="items-center w-full max-w-4xl px-5 mx-auto lg:px-16">
          <ul className="grid grid-cols-1 gap-4 list-none lg:gap-12 pb-10">
          <li className="flex items-center">
            <i className='text-4xl bx bxs-certification pr-8'></i>
            <div>
              <h3 className="mt-5 text-lg font-medium leading-6 text-slate-950">
                Certified and licenced
              </h3>
              <p className="mt-2 text-lg text-gray-800">
                Our electricians are certified through Napit, EAL City and Guild, and carry JIB Gold Cards. Our electricians under take regular training to ensure that they are up to date with all industry best practices
              </p>
            </div>
          </li>

          <li className="flex items-center">
            <i className="text-4xl bx bxs-plug pr-8"></i>
            <div>
              <h3 className="mt-5 text-lg font-medium leading-6 text-slate-950">
                Comprehensive Service
              </h3>
              <p className="mt-2 text-lg text-gray-800">
                Our wide services range will meet all your electrical needs from residential wiring to commercial installations and much more
              </p>
            </div>
          </li>
          <li className="flex items-center">
            <i className="text-4xl bx bxs-user-check pr-8"></i>
            <div>
              <h3 className="mt-5 text-lg font-medium leading-6 text-slate-950">
                Experienced Professionals
              </h3>
              <p className="mt-2 text-lg text-gray-800">
                We have 7 years of experience in the electrical industry, so our skills can take on any challenge.
              </p>
            </div>
          </li>
          <li className="flex items-center">
            <i className="text-4xl bx bxs-heart pr-8"></i>
            <div>
              <h3 className="mt-5 text-lg font-medium leading-6 text-slate-950">
                Customer Satisfaction
              </h3>
              <p className="mt-2 text-lg text-gray-800">
                Our customers are our top priority. We don't consider a job done until you're fully satisfied.
              </p>
            </div>
          </li>
          <li className="flex items-center">
            <i className="text-4xl bx bx-pound pr-8"></i>
            <div>
              <h3 className="mt-5 text-lg font-medium leading-6 text-slate-950">
                Transparent Pricing
              </h3>
              <p className="mt-2 text-lg text-gray-800">
                We believe in fair and transparent pricing without hidden costs. Our quotes are clear and detailed so you know exactly what you're paying for.
              </p>
            </div>
          </li>

          </ul>

          <p className="text-xl font-semibold mt-10 mb-12 text-slate-950 text-center">
              Call us today at
              <a href="tel:07469469586" className="text-right text-blue-700 hover:underline"> 07469 469586 </a>
              or email us at
              <a href="mailto:ciarant@norfolkcurrent.co.uk" className="text-right text-blue-700 hover:underline"> ciarant@norfolkcurrent.co.uk </a>
          </p>

            <div className=" flex justify-center">
            <Link to="/contact">
              <button className="bg-blue-950 text-white font-semibold rounded-lg py-3 px-8 shadow-lg tracking-wide">
                Send us an email
              </button>
            </Link>
          </div>

        </div>
        </div>

      </section>

    </Layout>
  )
}

export default IndexPage
